/*!
Pure v1.0.1
Copyright 2013 Yahoo!
Licensed under the BSD License.
https://github.com/pure-css/pure/blob/master/LICENSE.md
*/
/*csslint adjoining-classes: false, box-model:false*/
.pure-menu {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.pure-menu-fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
}

.pure-menu-list,
.pure-menu-item {
    position: relative;
}

.pure-menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.pure-menu-item {
    padding: 0;
    margin: 0;
    height: 100%;
}

.pure-menu-link,
.pure-menu-heading {
    display: block;
    text-decoration: none;
    white-space: nowrap;
}

/* HORIZONTAL MENU */
.pure-menu-horizontal {
    width: 100%;
    white-space: nowrap;
}

.pure-menu-horizontal .pure-menu-list {
    display: inline-block;
}

/* Initial menus should be inline-block so that they are horizontal */
.pure-menu-horizontal .pure-menu-item,
.pure-menu-horizontal .pure-menu-heading,
.pure-menu-horizontal .pure-menu-separator {
    display: inline-block;
    *display: inline;
    zoom: 1;
    vertical-align: middle;
}

/* Submenus should still be display: block; */
.pure-menu-item .pure-menu-item {
    display: block;
}

.pure-menu-children {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 3;
}

.pure-menu-horizontal .pure-menu-children {
    left: 0;
    top: auto;
    width: inherit;
}

.pure-menu-allow-hover:hover > .pure-menu-children,
.pure-menu-active > .pure-menu-children {
    display: block;
    position: absolute;
}

/* Vertical Menus - show the dropdown arrow */
.pure-menu-has-children > .pure-menu-link:after {
    padding-left: 0.5em;
    content: "\25B8";
    font-size: small;
}

/* Horizontal Menus - show the dropdown arrow */
.pure-menu-horizontal .pure-menu-has-children > .pure-menu-link:after {
    content: "\25BE";
}

/* scrollable menus */
.pure-menu-scrollable {
    overflow-y: scroll;
    overflow-x: hidden;
}

.pure-menu-scrollable .pure-menu-list {
    display: block;
}

.pure-menu-horizontal.pure-menu-scrollable .pure-menu-list {
    display: inline-block;
}

.pure-menu-horizontal.pure-menu-scrollable {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    /* a little extra padding for this style to allow for scrollbars */
    padding: .5em 0;
}

/* misc default styling */

.pure-menu-separator,
.pure-menu-horizontal .pure-menu-children .pure-menu-separator {
    background-color: #ccc;
    height: 1px;
    margin: .3em 0;
}

.pure-menu-horizontal .pure-menu-separator {
    width: 1px;
    height: 1.3em;
    margin: 0 .3em ;
}

/* Need to reset the separator since submenu is vertical */
.pure-menu-horizontal .pure-menu-children .pure-menu-separator {
    display: block;
    width: auto;
}

.pure-menu-heading {
    text-transform: uppercase;
    color: #565d64;
}

.pure-menu-link {
    color: #777;
}

.pure-menu-children {
    background-color: #fff;
}

.pure-menu-link,
.pure-menu-disabled,
.pure-menu-heading {
    padding: .5em 1em;
}

.pure-menu-disabled {
    opacity: .5;
}

.pure-menu-disabled .pure-menu-link:hover {
    background-color: transparent;
}

.pure-menu-active > .pure-menu-link,
.pure-menu-link:hover,
.pure-menu-link:focus {
    background-color: #eee;
}

.pure-menu-selected > .pure-menu-link,
.pure-menu-selected > .pure-menu-link:visited {
    color: #000;
}
